import * as $ from 'jquery';

require('./bootstrap');

$(document).ready(function() {
    let $nextStep = $('#next-step');
    let $confirmModal = $('#confirm-modal');
    let url = null;

    setupSearchEngine();

    $('.show-confirm-modal').on('click', function(e) {
        e.preventDefault();

        url = $(this).attr('href');

        $confirmModal.modal({
            backdrop: 'static',
            show: true
        });
    });

    $confirmModal.on('click', '.btn', function(e) {
        let decision = $(this).data('decision');

        if(decision === 'yes') {
            window.location.href = url;
        }

        url = null;
        $confirmModal.modal('hide');
    });

    $('#info-modal .modal-close').on('click', function(e) {
        $('#info-modal').modal('hide');
    });

    function showCardInfo(event) {
        let src = $(this).data('src');
        let external = $(this).data('external');

        let modal = $('#info-modal');
        let modalBody = modal.find('.modal-body');
        let iframe = modal.find('iframe');


        $(window).on('resize', function() {
            iframe.height($(window).height() - 82);
        });

        iframe.height($(window).height() - 82);

        modal.find('#external').attr('href', external);

        modal.modal('show');

        iframe.attr('src', src);

        iframe.on('load', function($event) {
            console.log('iframe loaded!');

            let loadedIframe = this;

            setTimeout(function() {
                console.log('iframe js initialized!');
                let iframeHeight =
                    loadedIframe.contentWindow.document.body.offsetHeight + 50 + 'px';

                // modalBody.height(iframeHeight);
                modalBody.removeClass('loading');
                iframe.removeClass('invisible');
            }, 200);
        });

        modal.on('hidden.bs.modal', function() {
            modalBody.addClass('loading');
            modalBody.removeAttr('style');
            iframe.addClass('invisible');
            iframe.html('');
        });
    }

    $('.add-product-form').on('submit', function(e) {
        console.log('form..');
        e.preventDefault();
        let $form = $(this);

        $.post($form.attr('action'), {
            _token: $form.find('input[name=_token]').val(),
        }, function(res) {
            console.log('res...', res);
        })
    });

    $(document).on('click', '.card-info', showCardInfo);

    $nextStep.click(function(e) {
        $('form').submit();
    });

    $(document).on('click', '.switch-control', function(e) {
        let $switch = $(this).closest('.switch');
        $switch.toggleClass('active');
        $switch.trigger('change', $switch.hasClass('active'));
    });

    $(document).on('change', '.switch', function(e, value) {
        console.log('change', e, value);
    });

    $(document).on('change', '.product-checkbox', function(e) {
        let isChecked = $(this).prop('checked');
        let length = $('.product-checkbox:checked').length;

        if(isChecked) {
            $(this).closest('.card').addClass('is-added');
        } else {
            $(this).closest('.card').removeClass('is-added');
        }

        if(length > 0) {
            $nextStep.find('.no').html(length);
            $nextStep.attr('disabled', null);
        } else {
            $nextStep.find('.no').html('');
            $nextStep.attr('disabled', 'disabled');
        }
    });

    function setupSearchEngine() {
        let data = [];

        let $search = $('#search');
        let $cancel = $('.search-cancel');

        if(!$search.length) {
            return;
        }

        $search.focus();

        $('.card-outer').each(function(e) {
            data.push({
                title: $.trim($(this).find('.card-title').text()).toLowerCase(),
                el: $(this)
            });
        });

        $search.on('input', function(e) {
            let value = $(this).val().toLowerCase();

            if(value.length > 0) {
                $cancel.show();
            } else {
                $cancel.hide();
            }

            data.forEach((item) => {
                if(item.title.indexOf(value) !== -1) {
                    item.el.removeClass('d-none');
                } else {
                    item.el.addClass('d-none');
                }
            });
        });

        $cancel.on('click', function() {
            $search.val('');
            $search.trigger('input');
        });
    }

    $('.show-more').on('click', function(e) {
        e.preventDefault();

        let $prev = $(this).prev();
        if($prev.hasClass('hidden')) {
            $prev.removeClass('hidden');
            $(this).text('Pokaż mniej');
        } else {
            $prev.addClass('hidden');
            $(this).text('Pokaż więcej');
        }
    });

    $(document).on('click', '.action-item', function(e) {
        let $target = $($(this).data('target'));

        $(this).closest('.action-switch').find('.action-item').each(function() {
            $target.removeClass($(this).data('mode'));
            $(this).removeClass('active');
        });

        $target.addClass($(this).data('mode'));
        $(this).addClass('active');
    })
});
